import React from "react";
import "../fonts/brands.css"
import "../fonts/solid.css"
import "../fonts/fontawesome.css"
import "../App.css";

const ListItem = ({ link, icon }) => {
    return <a href={link} target="_blank" rel="noopener noreferrer"><i className={icon}></i></a>;
};

export default ListItem;
