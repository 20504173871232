import React, { useState } from "react";
import "../App.css";
import sha256 from "crypto-js/sha256";

const LoginCard = ({ title, desc, theme, iconData }) => {
    const [inputField, setInputField] = useState({
        password: "",
    });

    const passwordHandler = (e) => {
        setInputField({ password: e.target.value });
    };

    const loginHandler = (event) => {
        event.preventDefault();
        const domain = window.location.hostname;
        const passHash = sha256(inputField.password);
        const url = `http://user:${passHash}@${domain}/dashboard`;
        window.location.href = url;
        console.log(url);
        console.log("YOU PRESSED THE PASSWORD BUTTON ALL IS GOING TO EXPLODE");
    };

    return (
        <div
            className="Card"
            style={{ background: theme, backgroundSize: "1200% 1200%" }}
        >
            <div className="description">
                <label>{title}</label>
                <p>BorisHub Dashboard</p>
                <form className="input-form" onSubmit={loginHandler}>
                    <input
                        className="input-box password"
                        type="password"
                        id="password"
                        onChange={passwordHandler}
                        name="password"
                        value={inputField.password}
                        placeholder="Password"
                    />
                    <button className="input-button">Login</button>
                </form>
            </div>
        </div>
    );
};

export default LoginCard;
