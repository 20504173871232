import "./App.css";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Redirect,
} from "react-router-dom";
import "overlayscrollbars/overlayscrollbars.css";
import Card from "./components/Card";
import LoginCard from "./components/LoginCard";
import React from "react";
const { cards, hiddenCards } = require("./Cards.json");
console.log(cards);
function App() {
    return (
        <div>
            <div className="background" />
            <div className="titlebar">
                <panel id="Title">BorisHub</panel>
            </div>
            <div className="wrapper">
                {cards.map((card) => (
                    <Card
                        key={card.title}
                        title={card.title}
                        desc={card.desc}
                        theme={card.theme}
                        iconData={card.iconData}
                    />
                ))}
                <Router>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <LoginCard
                                    title="Login"
                                    theme="rgba(255, 255, 255, 0.2)"
                                />
                            }
                        />
                        <Route
                            path="/dashboard"
                            element={hiddenCards.map((card) => (
                                <Card
                                    key={card.title}
                                    title={card.title}
                                    desc={card.desc}
                                    theme={card.theme}
                                    iconData={card.iconData}
                                />
                            ))}
                        ></Route>
                    </Routes>
                </Router>
            </div>
        </div>
    );
}

export default App;
